import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-duracreto.azurewebsites.net/api/v1/",
  googleMapskey: "AIzaSyCoF2frpi0FD7ZW0Y2NNiQ8NJ8ZqXiWoug",
  sentryDSN: "https://b3a70158ac9d56f28a31ddbcc6329254@o4505172191608832.ingest.us.sentry.io/4507925125398528",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}Duracreto - Panel Administrativo`,
};
